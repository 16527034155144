/********************************** General Page Css *****************************/
body {
  background-color: #23414F;
  text-align: center;
  color: #BBC8CE;
}

p {
  color:  #BBC8CE;
  margin-bottom: 8px;
  text-align: left;
  font-size: 1.1rem;
}

li {
  font-size: 1.1rem;
}

.App {
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;
}

h1, h2, h3, h4, h5 {
  font-weight: 400;
}

/* h4 {
  padding: 8px 0 0 0;
} */

.blurb {
  padding: 10px;
  max-width: 900px;
  margin: auto;
}

blockquote {
  padding-left: 5%;
  padding-right: 10%;
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 8px;
}

a {
  color: #888A26;
}

a:hover{
  color: #6B6B1D
}

.getting-started{
  text-align: center;
  padding-top: 10px;
  margin-bottom: -10px;
}

/* ******************************** Blog ********************************************* */
#blog-main {
  padding: 15px;
}

.blog-post {
  max-width: 900px;
  margin: auto;
  padding: 10px 15px;
}

.blog-title {
  font-size: 2rem;
  /* margin: 25px; */
  padding: 8px 10px 10px 10px 
}

.blog-content {
  text-align: left;
}

.page{
  padding: 50px;
  text-align: left;
}

/* **************************** Blog Filters ************************************** */
.blog-button{
  position: relative;
  padding: 0 10px;
  margin: 0;
  background: #FCCC8A;
  font-size: 1.55rem;
  /* font-stretch: expanded; */
  font-weight: 700;

  color: white;
  /* text-shadow: -1.5px -1.5px 0 black, 1.5px -1.5px 0 black, -1.5px 1.5px 0 black, 1.5px 1.5px 0 black; */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  top: -2px;
  margin: 0 8px ; 
  border-radius: 10px;
  border: 2px solid #473423;
  box-shadow: 2px 7px 5px #473423, 1px 3px 8px #EAD5C0 inset ; 
}

.blog-button-inactive:hover {
  background: #CC9A58;
  box-shadow: 0 2px 5px #473423 inset, -1px -4px 5px #F2C18A inset;
}

.blog-button-active {
  background: #7BBE39;
  box-shadow: 2px 7px 5px #473423, 1px 3px 8px #A5FC4E inset ; 
}

.blog-button-active:hover {
  background: #4e7924;
  /* box-shadow: 2 2px 5px #, 1px 3px 8px #A5FC4E inset ;  */
  box-shadow: 0 2px 5px #3d5e1c inset, -1px -4px 5px #7BBE39 inset;

}

/*********************************** Main Menu **************************/

.site-menu {
  padding: 10px;
  max-width: 1280px;
  margin: auto;

}

.submenu {
  display: block;
}

.menu-card {
  position: relative;
  box-shadow: 2px 4px 4px #073549, 1px -1px 4px #073549;
  padding: 8px 8px 0 8px ;
  border-radius: 15px;
  width: 300px;
  height: 100px;
  /* max-width: 300px; */
  display: inline-block;
  margin: 3px ;
  text-align: left;
  vertical-align: top;
}

.menu-card h5 {
  
  display: inline;
  /* right: 5px;  */
  position: absolute; 
  padding-left: 2px;
  margin: 0;
}

.menu-card p {
  display: inline;
  position: relative;
  top: -9px; 
  padding-left: 2px;
   
}

.menu-card .button {
  display: inline;
  float: left;
  margin-left: 0; 
}



/*********************************** Preview Cards ************************************** */

.filter-preview {
  box-shadow: 2px 4px 4px #073549, 1px -1px 4px #073549;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  max-width: 900px;
  display: table;
  margin: 10px auto;
  text-align: center;
}


.card-preview{
  position: relative;
  box-shadow: 2px 4px 4px #073549, 1px -1px 4px #073549;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 900px;
  display: table;
  margin: 14px auto;
  text-align: left;
}

.card-preview .cover-img{
  width: 45%;
  border-radius: 10px;
}

.card-preview:nth-child(odd) .cover-img{
  float: right;
  margin-left: 20px;
}

.card-preview:nth-child(even) .cover-img{
  float: left;
  margin-right: 20px;
}

.card-preview h2 {
  /* top: 5px; */
  display: inline-block;
  width: 450px; 
  position: relative;
}

.card-preview .excerpt {
  /* margin-top: 10px; */
  display: block;
}

.card-preview a {
  height: 40px;
  
}

.card-preview .news-date {
  position: relative;
  /* padding-left: 20px; */
  top: -8px;
}

.blog-card-wrapper {
  display: inline-block;
  position: relative;
  max-width: 450px;
}

/* .card-preview .blog-difficulty { */
.blog-difficulty {
  position: relative;
  top: -4px;
  padding: 6px 2px 0 2px;
  bottom: 0;
  right: 0;
  /* float: left; */
  height: 42px;
  /* border: 2px solid red; */
}

@media screen and (max-width: 992px) {
  .blog-card-wrapper {
    max-width: 52%;
  }

  .card-preview h2 {
    top: 5px;
    display: block;
    width: 100%;  
  }

  .card-preview h2 { 
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 760px) {
  .blog-card-wrapper {
    max-width: 50%;
  }

}

@media screen and (max-width: 576px) {
  .card-preview .cover-img{
    width: 100%;
    float: none;
    margin: 0;
  }

  .blog-card-wrapper {
    max-width: 100%
  }
}
/* *********************************** Embedded Images ************************************* */

.pimage {
  /* padding: 5px; */
  width: 100%;
  padding: 0;
  margin-top: 5px;
}

.psm {
  width: 20%;
}

.pmd {
  width: 45%;
}

.pth {
  width: 100px;
  /* margin:5px; */
}

.pum {
  width: 60px;
}

.prt {
  float: right;
  margin-left: 5px;
}

.plt {
  float: left;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .psm {
    width: 30%;
  }
  
  .pmd {
    width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .psm {
    width: 45%;
  }
  
  .pmd {
    width: 100%;
    margin: 0 auto;
  }
}

.natural-image {
  max-width: 100%;
}

/* ********************************* News Notification ******************************* */
.news-notification{
  position: relative;
  width: 290px;
  text-align: center;
  padding: 5px;
  margin: 20px auto;
  margin-bottom: -10px;
  display: inline-block;
  border: 2px solid #F46611;
  border-radius: 6px;
 
}

.news-notification-hidden {
  display: none;
}

.news-notificaiton-thumb {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 5px;
}

.news-notification-arrow {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 5px;
}

.new-news {
  border: 2px solid #F46611;
  /* box-shadow: -1px 2px 3px #AD2D02, -1px -1px 3px #F46310, 11px -11px 13px yellow; */
  box-shadow: 1px 1px 4px #F36A13
}

.news-howto {
  color: #F46611;
  position: absolute;
  bottom: 6px;
  right: 12px;
}

/* ******************************** News Widget ****************************************/
.news {
  padding: 8px;
}


/* ******************************** Support Page ************************************* */
.support {
  max-width: 900px;
  padding: 20px 20px;
  margin: auto;
}

/* *********************************Guide Page *************************************** */
.guide-image{
  max-width: 100%
}

/* ********************************** Scroller Headers ********************************* */

.scroller-header {
  display: inline-block;
  width: 100%;
  max-width: 900px;
  position: relative;
  padding: 10px;
  margin-top: 10px;
  /* margin: 10px; */
  vertical-align: text-bottom;
  /* background: url("../public/images/dream/clouds1.png") */
}

.building-tables .scroller-header {
  max-width: 600px;
}

.scroller-header p{
  display: inline-block;
  position: absolute;
  bottom: 6px;
  right: 90px;
}

.scroller-left{
  float: left;
}

.scroller-right{
  float: right;
}

/* *******************************  */



/* Responsive layout - when the screen is less than 400px wide */
@media screen and (max-width: 576px) {
  
  /* .card-preview .cover-img{
    width: 100%;
    float: none;
    margin: 0;
  } */

  .psm {
    width: 45%;
  }
  
  .pmd {
    width: 100%;
  }

}

/* ********************************************** Water Mark ************************************************** */

.watermark, .watermark-sm, .watermark-vil {
  position: absolute;
  color: #BBC8CE;
  font-size: 1.7rem;
  bottom: 10%;
  right: 5%;
  text-shadow: -1px -1px 0 #23414F, 1px -1px 0 #23414F, -1px 1px 0 #23414F, 1px 1px 0 #23414F;
  opacity: .25;
  transform: rotate(-20deg);
  z-index: 50;
}

.building-tables .watermark {
  left: 180px;
  top: 220px;
}

.watermark-building-complex {
  position: absolute;
  color: #BBC8CE;
  /* color: red; */
  font-size: 1.7rem;
  bottom: 10%;
  right: 5%;
  text-shadow: -1px -1px 0 #23414F, 1px -1px 0 #23414F, -1px 1px 0 #23414F, 1px 1px 0 #23414F;
  opacity: .25;
  transform: rotate(-20deg);
  z-index: 50;
  left: 50px;
  top: 110px;
}

.building-tables .watermark-sm {
  left: 180px;
  top: 110px;
  transform: rotate(-10deg);
}

.ce-main-title .watermark {
  top: 160px;
  left: 0px;
}

.watermark-vil {
  transform: rotate(-30deg);
  top: 250px;
  left: 5px;
}

@media screen and (max-width: 576px) {
  .building-tables .watermark {
    left: 80px;
    top: 220px;
  }
  
  .building-tables .watermark-sm {
    left: 80px;
    top: 130px;
    transform: rotate(-10deg);
  }

  .watermark, .watermark-sm {
    right: 25%;
    
  }

}


/* **************************************** Building Tables ************************************************** */
.building-table-overflow {
  overflow-x: auto;
}

.building-table {
  margin: 5px auto;
  border: 1px solid #BBC8CE;
  text-align: center;
  max-width: 95%;
  /* table-layout: auto; */
  /* min-width: 500px; */
}

#buildings th {
  position: relative;
  padding: 5px 10px;
  background-color: #23414F;
  color: #BBC8CE;
  font-weight: 500;
  font-size: 1.1rem;
  /* width: 110px; */
}

.building-table td {
  padding: 5px 10px;
  white-space: nowrap;
}

.building-name {
  position: relative;
  color:  #BBC8CE;
  display: inline-block;
  top: 5px;
  margin: 0; 
  font-weight: 400;
  font-size: 1.7rem;
  width: 280px;

}

.building-large {
  /* width: 105px; */
  height: 70px;
  margin: 2px 10px;  
}

.floating-table {
  float: right;
}

.floating-table h4{
  text-align: center;
}

/* .building-clouds {
  max-width: 100px;
  position: absolute;
  bottom: 0;
  left: 20px;
  
}

.building-cloud-container {
  display: inline;
  position: relative;
} */

.building-produce {
  width: 45px;
  margin: 5px 10px 5px 10px;
}

.building-tables .button {
  width: 46px;
  height: 46px;
     
  margin: 3px 2px; 
  border-radius: 8px;
}
  
.building-tables .button-icon{
  /* margin-left: -3px; */
  width: 38px;
  height: 38px;
  /* box-shadow: 10px 10px #473423; */
}

.building-table {
  overflow-x: auto;
}

#buildings td {
  margin: 5px, 5px;
  font-size: 1.2rem;
}

/* #buildings .building-level { */
.building-level {
  position: relative;
  top: -1px;
  margin-left: 5px;
  height: 30px;
  width: 30px;
  z-index: 0;
}

.dream-level {
  position: relative;
  top: -1px;
  margin: 0 10px;
  max-height: 50px;
  /* width: 50px; */
  z-index: 0;
}

.unlock-container {
  position: relative;
}

.unlock-xp-icon {
  position: relative;
  height: 30px;
  width: 30px;
}

.unlock-xp {
  position: absolute;
  text-align: center;
  margin: auto;
  top: 4px; right: 0; bottom: 0; left: 3px;
  text-shadow: -1.1px -1.5px 0 #23414F, 1.1px -1.5px 0 #23414F, -1.5px 1.1px 0 #23414F, 1.5px 1.1px 0 #23414F;
  font-size: 1rem;
}

#buildings tr {
height: 40px;
}

#buildings tr:nth-child(even) {
  background-color: #23414F;
  color: #BBC8CE;
  font-weight: 500;

}

#buildings tr:nth-child(odd) {
  background-color: #BBC8CE;
  color:  #23414F;
  font-weight: 500;
}

.building-menu{
  margin: 10px 0;
}

/* ****Building Variants */
.building-variants{
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 0px;
  margin: -5px auto 0;
}

.building-variants h4 {
  font-size: 1.1rem;
  margin: -14px auto 0px;
}

.building-variant{
  width: 80px;
  margin: 2px 6px;  
}

.building-blurb {
  /* border: 5px solid red; */
  padding: 10px;
  max-width: 900px;
  margin: auto;
  text-align: center;
}

/* Responsive layout - when the screen is less than 800px wide */
@media screen and (max-width: 992px) {
  .building-name {
    top: 5px;
    font-size: 1.7rem;
  }
  
  .building-large {
    height: 60px;
    margin: 3px 6px;  
  }
  
  .building-produce {
    width: 40px;
    margin: 3px 6px;
  }
  
  .building-tables .button {
    width: 42px;
    height: 42px;   
    border-radius: 7px;
  }
    
  .building-tables .button-icon{
    width: 34px;
    height: 34px;
  }
}

/* Responsive layout - when the screen is less than 400px wide */
@media screen and (max-width: 576px) {
  .building-name {
    top: 5px;
    margin: 0 auto;
    font-size: 1.5rem;
    display: block;
  }
  
  .building-large {
    height: 50px;
    margin: 3px 5px;  
  }
  
  .building-produce {
    display: none;
  }
  
  .building-tables .button {
    width: 36px;
    height: 36px;   
    border-radius: 6px;
  }
    
  .building-tables .button-icon{
    width: 28px;
    height: 28px;
  }

  .building-variants h4 {
    font-size: 1rem;
    margin: 0 auto 0;
  }
  
  .building-variant{
    width: 58px;
    margin: 2px 4px;  
  }
  
  .floating-table {
    float: none;
  }
  
}

/* ****************************** Dream Calc Tables ********************************************** */

.dream-calc-page {
  max-width: 900px;
  margin: auto;
  padding: 4px 12px;
}

.dream-calc-card {
  box-shadow: 2px 4px 4px #073549, 1px -1px 4px #073549;
  padding: 10px;
  border-radius: 12px;
  /* width: 100%; */
  max-width: 900px;
  min-width: 290px;
  display: table;
  margin: 10px auto; 
  text-align: center;
}

@media screen and (max-width: 356px) {
  .dream-calc-card {
    padding: 8px;
    min-width: 275px;
  }
}

.dream-calc-title-img {
  max-width: 65px;
  margin-right: 5px;
}

.dream-calc-table {
  margin: 0 auto;
}

.calc-line {
  border-top: 1px solid #BBC8CE;
}

.total-table-contract-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  width: 96px;
  height: 79px;               /* width * 202 / 248 */
  /* border: 1px solid red; */
}

.total-table-blank-contract {
  position: absolute;
  top: 2px; left: 2px;
  width: 92px;
  z-index: 0
}

.total-table-material{
  position: absolute;
  top: 10px;
  left: 28px;
  /* margin-left: 5px; */
  height: 40px;
  width: 40px;
  z-index: 1;
}

.total-table-number {
  position: absolute;
  width: 96px;
  /* border: 1px solid red; */
  bottom: 6px;
  left: 0px;
  color: #4A3008;
  z-index: 2;
  font-weight: 500;
  /* text-align: center; */

}

.total-table-contract-container-cash {
  display: inline-block;
  position: relative;
  padding: 2px;
  width: 152px;
  height: 79px;               /* width * 202 / 248 */
  /* border: 1px solid red; */
}

.total-table-blank-contract-cash {
  position: absolute;
  top: 2px; left: 2px;
  width: 148px;
  
  z-index: 0
}

.total-table-material-cash {
  position: absolute;
  top: 10px;
  left: 56px;
  /* margin-left: 5px; */
  height: 40px;
  width: 40px;
  z-index: 1;
}

.total-table-number-cash {
  position: absolute;
  width: 152px;
  /* border: 1px solid red; */
  bottom: 5px;
  left: 0px;
  color: #4A3008;
  z-index: 2;
  font-weight: 500;
  /* text-align: center; */

}



/* .dream-total-table td { */
/* .dream-total-square { */
  /* min-width: 125px;
  max-width: 160px;
  padding: 5px;
  margin: 5px;
  text-align: right;
  
} */

.dream-building-table {
  width: 100%
}

.dream-building-table h4 {
  max-width: 260px;
}
.dream-building-table td {
  /* .dream-total-square { */
    /* border: 1px solid red; */
  min-width: 88px;
  /* max-width: 100px; */
  padding: 5px;
  margin: 5px;
  text-align: right;
  
}
  
.dream-calc-button{
  position: relative;
  padding: 4px 10px;
  margin: 0;
  font-size: 1.55rem;
  font-weight: 700;

  color: white;
  /* text-shadow: -1.5px -1.5px 0 black, 1.5px -1.5px 0 black, -1.5px 1.5px 0 black, 1.5px 1.5px 0 black; */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  top: 2px;
  margin: 0 8px ; 
  border-radius: 10px;
  border: 2px solid #473423;
}

.dream-calc-inactive{
  background: #CB291D;
  box-shadow: 2px 7px 5px #473423, 1px 3px 8px #FA345C inset;
}

.dream-calc-inactive:hover {
  background: #A3211F;
  box-shadow: 0 2px 5px #473423 inset, -1px -4px 5px #FA345C inset;
}

.dream-calc-active {
  background: #7BBE39;
  box-shadow: 2px 7px 5px #473423, 1px 3px 8px #A5FC4E inset ; 
}

.dream-calc-active:hover {
  background: #4e7924;
  box-shadow: 0 2px 5px #3d5e1c inset, -1px -4px 5px #7BBE39 inset;
}

.dream-building-table .button {
  width: 40px;
  height: 40px;
        
  margin: 3px 2px; 
  border-radius: 8px;
}

.dream-building-table .button-icon{
    /* margin-left: -3px; */
    width: 32px;
    height: 32px;
    /* box-shadow: 10px 10px #473423; */
}
  
.dream-calc-adjust-div {
  text-align: right;
}

.dream-calc-adjust-variable {
  /* display: inline; */
  width: 80px;
  /* border: 1px solid red */
} 

.dream-calc-adjust-text {
  /* border: 1px solid red; */
  font-size: 1.15rem;
}

/* ******************************* Tree of Life *************************************************** */
.branch-rewards {
  width: 30px;
  margin-left: 5px;
  
}

/* ************************************ Calendar ************************************************ */
.ce-main-title {
  display: block;
  position: relative;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 10px auto 15px auto;
  /* padding: 20px; */
}

.calender-widget {
  max-width: 300px;
  margin: 0 auto; 
  padding: 5px; 
}

.calendar-event-box{
  position: relative;
  padding: 5px;
  margin: 5px 0;
  border: 2px solid;
  border-radius: 6px;
  text-align: left;
}

.calendar-event-thumb {
  position: absolute;
  height: 50px;
  width: 50px;
  /* float: left; */
  
  /* margin: 0 3px 0 0; */
}

.calendar-event-title {
  position: relative;
  max-width: 195px;
  top: -5px;
  font-size: 1.35rem;
  left: 55px;
  margin: 0;
  align-content: center;
}

.calendar-event-date {
  position: relative;
  max-width: 225px;
  left: 55px;
  margin: -6px 0 0 0;  
  text-align: left;
}

.show {
  display: block;
  transition: display 1s;
}

.hide {
  display: none;
  transition: display 1s;
}
/* ******************************** More Info ****************************************************** */

.more-info-button {
  float: right;
  position: relative;
  padding: 0;
  width: 30px;
  height: 30px;
  background: #375092;
  top: 0;
  right: 0;
  z-index: 10;
  margin: 0; 
  border-radius: 5px;
  border: 1px solid #BBC8CE;
  box-shadow: 2px 7px 5px #3B3B3B, 1px 3px 8px #3B3B3B inset ; 
}

.more-info-button:hover {
  background: #283265;
  box-shadow: 0 2px 5px #375092 inset, -1px -4px 5px #3B3B3B inset;
}

.more-info-button:focus {
  outline: 0;
}

.more-info-button-icon{
  position: absolute;
  top: 3px;
  left: 7px;
  width: 15px;
  height: 19.5px;
}
/* ************************************* Contact Form **************************************************** */
.contact-form {
  width: 100%;
  max-width: 750px;
  margin: auto;
  padding: 20px;
  /* border: 1px solid #BBC8CE;
  border-radius: 4px; */
}

.contact-form input {
  display: block;
  margin: 20px 0;
  width: 50%
}

.contact-form textarea {
  width: 100%; 
}

.contact-form input:invalid, .contact-form textarea:invalid{
  border-left: 1px solid #E73630;
}

.contact-form input:valid, .contact-form textarea:valid{
  border-left: 1px solid #4E9600;
}

.contact-form button {
  display: block;
  border: 1px solid #BBC8CE;
  border-radius: 4px;
  background-color: #23414F;
  color: #BBC8CE;
  padding: 6px 10px;
  margin-top: 10px;
}

.contact-form input, .contact-form textarea{
  border: 1px solid #BBC8CE;
  border-radius: 4px;
  background-color: #23414F;
  color: #BBC8CE;
  padding: 5px 10px;  
}

.message-submit {
  padding: 10px 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus
{
  border: 1px solid #BBC8CE;
  -webkit-text-fill-color: #BBC8CE;
  box-shadow: 0 0 0px 1000px #23414F inset;
  -webkit-box-shadow: 0 0 0px 1000px #23414F inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 576px) {
  .contact-form input {
    width: 100%
  }
}

/* ********************************************* Footer *************************************** */

.footer {
  margin-top: 20px;
  background-color: #23414F;
  box-shadow: 1px 4px 4px #073549, 1px -4px 4px #073549;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0; 

}

.logo-fb {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: 5px 10px 8px 10px;
  box-shadow: 6px 8px 8px #073549;
}

.logo-fb:hover {
  box-shadow: 2px 3px 4px #073549;
}

.footer p {
  position: relative;
  top: 13px;
  display: inline;
}

/* ****************************Link in footer************************** */
.footer .navbar-button {
  float: right;
}

.footer .button {
  width: 40px;
  height: 40px;
  margin: 5px 10px 8px 10px;
  border-radius: 8px;
  }

.footer .button-icon{
  width: 32px;
  height: 32px;
}

/* **************************************************** Header **************************************************** */
.header {
  padding: 15px 15px;
  text-align: center;
}

.logo {
  height: 100px;
  width: 100px;
}

.logo-fs {
  border-radius: 50px;
  box-shadow: 6px 8px 8px #073549;
}

.logo-fs:hover {
  box-shadow: 2px 3px 4px #073549;
}

.logo-fs:focus {
  outline: 0;
}

.header-title {
  position: relative;
  top: 6px;
  margin: 0 50px;
  display: inline-flex;
  color: #BBC8CE;
  font-size: 2.2rem;
  font-weight: 400;
  font: "Arial";
}

@media screen and (max-width: 992px) {
  .header {
    padding: 5px 5px 0 5px;
  }
  
  .logo {
    height: 60px;
    width: 60px;
  }
  
  .logo-fs {
    border-radius: 30px;
  }
  
  .header-title {    
    top: -9px;
    margin: 0 65px;
    max-width: 200px;
    font-size: 1.7rem;
    font-weight: 400;
    font: "Arial";
  }
}

@media screen and (max-width: 576px) {
  .header {
    padding: 5px 5px;
    text-align: left;
  }

  .logo-bf {
    display: none;
  }
    
  .header-title {
    top: -8px;
    margin: 0 0 0 10px;
    max-width: 180px;
    font-size: 1.35rem;
    font-weight: 400;
    font: "Arial";
  }
}

/* ******************************************** Home Page ***************************************** */
.home-left {
  width: 320px;
  float: left;
  margin: 0 auto;
}

.home-center {
  margin: 0 auto;
  overflow: hidden;
  padding-right: 20px;
}

@media screen and (max-width: 768px){
  .home-left {
    float: none;
  }

  .home-center {
    width: 100%;
    padding-right: 10px;    
  }
}


/* **************************** Cookie Notifications *******************/

.cookie-notification {
  min-height: 74px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 8px 16px;
  background-color: #AFFAFD;
  color: #23414F;
  box-shadow: 1px 4px 4px #123518;
  vertical-align: top;
  z-index: 102;
}

.cookie-text {
  color: #23414F;
  margin-right: 20px;
  padding-top: 12px;
  }

.cookie-button {
  float: right;
}

@media screen and (max-width: 992px) {
  .cookie-notification {
    min-height: 62px;
  }
  .cookie-text {
    padding-top: 8px;;
  }
}

@media screen and (max-width: 598px) {
  .cookie-text{
    padding-top: 0;
  }
}
/* ******************************************************* Navbar **************************************************** */
.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 8px 16px;
  justify-content: center;
  background-color: #23414F;
  box-shadow: 1px 4px 4px #073549, 1px -4px 4px #073549;
  z-index: 100;
}



.menu-content {
  display: block;
}

.button {
  position: relative;
  padding: 0;
  width: 60px;
  height: 60px;
  background: #FCCC8A;
  color: #FCCC8A;
  top: -2px;
  margin: 0 8px ; 
  border-radius: 10px;
  border: 2px solid #473423;
  box-shadow: 2px 7px 5px #473423, 1px 3px 8px #EAD5C0 inset ; 
  /* -webkit-transition: .1s;
  transition-timing-function: ease-in-out;
  transition: background .1s, box-shadow .1s;  */
  /* transition: box-shadow .5s; */
}

.button:hover {
  background: #CC9A58;
  box-shadow: 0 2px 5px #473423 inset, -1px -4px 5px #F2C18A inset;
}

.button:focus {
  outline: 0;
}

.button-icon{
  width: 48px;
  height: 48px;
}

/* ********************** */

.navbar-button { 
  position: relative;
  display: inline-block;
}


@media screen and (max-width: 992px) {
  .navbar {
    padding: 4px 8px;
  }

  .button {
    width: 48px;
    height: 48px;
    margin: 1px 3px 0 3px; 
    border-radius: 8px;
    }
  
  .button-icon{
    width: 38px;
    height: 38px;
  }
}

@media screen and (max-width: 576px) {  
  .navbar {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    
  }

  .navbar .menu-content {
    /* display: none; */
    position: relative;
   
    background-color: #23414F;
    box-shadow: 1px 4px 4px #073549, -1px 0 4px #073549;
  }

  .navbar:hover .menu-content {
    display: block;
  }
  
 

  .button {
    width: 48px;
    height: 48px;  
    margin: 3px 3px; 
    border-radius: 8px;
    }
  
  .button-icon{
    width: 38px;
    height: 38px;
  }

  .building-menu .button {
    width: 38px;
    height: 38px;   
    margin: 3px 2px; 
    border-radius: 8px;
    }
  
  .building-menu .button-icon{
    width: 30px;
    height: 30px;
  }

}

/* ****************************** Seasonal Events ****************************************** */
.seasonal-header {
  display: inline-block;
  width: 100%;
  max-width: 600px;
  position: relative;
  padding: 10px;
  margin-top: 10px;
  /* margin: 10px; */
  vertical-align: text-bottom;
}

.seasonal-task {
  position: relative;
  top: -1px;
  margin-left: 5px;
  height: 45px;
  width: 45px;
  z-index: 0;
}

.seasonal-unlock {
  position: absolute;
  top: 12px;
  left: -36px;
  height: 30px;
  width: 30px;
}

/* *********************************************** Theme Tables ************************************** */
.theme-blurb {
  max-width: 900px;
  margin: 10px auto;
}

.theme-event-master{
  
  position: relative;
  width: auto;
  margin: auto;
  display: inline-block;
}



.theme-header {
  position: relative;
  margin: 0 auto;
  height: 80px;
}

/* ****************************** Theme Header ********************************** */
.theme-header h1 {
  position: absolute;
  bottom: -5px;
  left: 100px;
  text-align: left;
  font-weight: 400;
  font-size: 2.4rem;
}

.theme-event-master .deco {
  position: absolute;
  bottom: 0;
  left: 5px;
  height: 100%;
  margin: 0;
}

.table-levels .button {
  width: 46px;
  height: 46px;
     
  margin: 3px 2px; 
  border-radius: 8px;
}
  
.table-levels .button-icon{
  width: 38px;
  height: 38px;
}


.table-levels {
  width: 150px;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}

.theme-header-img {
  position: absolute;
  bottom: .5%;
  left: 5%;
  max-width: 90%;
  max-height: 97%;
  opacity: .1;
}

.theme-table {
  margin: 5px auto;
  border: 1px solid #BBC8CE;
/* *************************************** Table Text Align ********************************* */
  text-align: right;
  overflow: auto;
}

#theme-table th {
  background-color: #23414F;
  color: #BBC8CE;
  font-size: 1.2rem;
  font-weight: 500;
  width: 120px;
  
}

#theme-table .level-icon{
  position: relative;
  width: 116px;
  top: -3px;
}

.theme-level-star{
  position: relative;
  padding: 2px;
  width: 34px;
  top: -4px;
  right: 28px;
  text-align: center;
}

.theme-level-text {
  position: relative;
  font-size: 1.6rem;
  margin-right: 3px;
  top: 4px;
  right: 25px;
}



.lrg-task-icon {
  height: 40px;
  width: 40px;
}



.container {
  position: relative;
}

#theme-table tr, #rate-table tr {
height: 40px;
}

#theme-table tr:nth-child(even), #rate-table tr:nth-child(even) {
  background-color: #23414F;
  color: #BBC8CE;
  font-weight: 400;

}

#theme-table tr:nth-child(odd), #rate-table tr:nth-child(odd){
  background-color: #BBC8CE;
  color:  #23414F;
  font-weight: 500;
}
 
#theme-table .left-border {
  border-left: 1px solid #BBC8CE;
}

#theme-table tbody tr:nth-child(odd) .left-border:nth-child(n+2){
  border-left: 1px solid #23414F;
}

#rate-table th {
  padding: 10px;
  background-color: #23414F;
  color: #BBC8CE;
  min-width: 60px
}

#rate-table {
  margin: 20px auto 5px;
}


.footNote{
  position: relative;
  top: -6px;
  text-align: center;
  font-size: 1rem;
  margin: 0 5px -10px 5px;
}

.customize-table{
  padding: 10px;
  float: left;
  max-width: 450px;
}

.customize-table p {
  text-align: justify;
}

.customize-table select{
  position: relative;
  border: 1px solid #BBC8CE;
  padding: 3px;
  background: #23414F;
  color: #BBC8CE;
}
/** Theme Decorations **/
.theme-decos{
  max-width: 602px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  
}

.deco-card {
  position: relative;
  margin: 10px;
  padding: 5px;
  border: 1px solid #BBC8CE;
  width: 180px;
  height: 250px;
}

.display-deco-red {
  width: 165px;
}
.display-deco-yellow {
  position: relative;
  top: 20px;
  width: 165px;
}
.theme-deco-happy-text{
  position: absolute;
  bottom: 0;
  right: 5px;
}
.theme-deco-ticket-text{
  padding-left: 5px;
}

.theme-deco-size-text{
  position: absolute;
  bottom: 6px;
  left: 9px;
}

.theme-strategy {
  max-width: 722px;
  padding: 0 10px 10px;
}


@media screen and (max-width: 992px) {
  /* Shrink buttons to better fit a smaller screen    */
  .theme-header {
    height: 60px;
  }
  
  .table-levels {
    width: 132px;
  }

  .theme-header h1 {
    left: 80px;
    font-size: 1.8rem;
  }

  .table-levels .button {
    width: 40px;
    height: 40px;
    margin: 3px 2px; 
    border-radius: 8px;
  }
    
  .table-levels .button-icon{
    width: 32px;
    height: 32px;
  }

  .customize-table{
    max-width: 350px;
  }

}

@media screen and (max-width: 576px) {
  .theme-header-img {
    left: 25%;
  }
  
  .theme-header {
    height: 60px;
    max-width: 80%;
  }
  
  .table-levels {
    position: absolute;
    width: 120px;
    bottom: -3px;
  }

  .theme-header h1 {
    top: 0px;
    left: 70px;
    font-size: 1.3rem;
  }


  .table-levels .button {
    width: 32px;
    height: 32px;
    margin: 3px 4px; 
    border-radius: 8px;
  }
    
  .table-levels .button-icon{
    width: 24px;
    height: 24px;
  }
  
  .customize-table{
    margin: 0 auto;
    float: none;
    max-width: 90%;
  }
  .theme-decos{
    margin: 0 auto;
    max-width: 202px;
  
  }

  .theme-strategy {
    max-width: 602px;
    padding: 0 28px 10px;
  }
}

/* ******************************* Co-op Projects ********************* */
.project-header{
  position: relative;
  height: 60px;
}

.project-data{
  position: relative;
}

.project-icon {
  position: relative;
  width: 150px;
}

.project-unlock-icon {
  position: absolute;
  height: 40px;
  top: 4px;
  right: 4px;
}

.project-unlock {
  position: absolute;
  width: 30px;
  text-align: center;
  top: 8px; right: 7px;
  font-weight: 600;
  color: #BBC8CE;
  text-shadow: -1.1px -1.5px 0 #23414F, 1.1px -1.5px 0 #23414F, -1.5px 1.1px 0 #23414F, 1.5px 1.1px 0 #23414F;
  font-size: 1.3rem;
}

.project-multiplier {
  position: absolute;
  top: 4px; left: 12px;
  font-weight: 600;
  text-shadow: -1.5px -1.5px 0 #23414F, 1.5px -1.5px 0 #23414F, -1.5px 1.5px 0 #23414F, 1.5px 1.5px 0 #23414F;
  font-size: 1.5rem;
  color: #D5DEE2;
}

.bonus-button {
  position: absolute;
  opacity: 0.9;
  left: 5px;
  top: 70px;
}


.tractor-level p {
  display: inline-block;
  position: absolute;
  top: 5px; left: 225px;
  font-weight: 700;
  width: 50px;
  text-align: center;
  text-shadow: -1.5px -1.5px 0 #23414F, 1.5px -1.5px 0 #23414F, -1.5px 1.5px 0 #23414F, 1.5px 1.5px 0 #23414F;
  font-size: 1.4rem;
  color: #D5DEE2;
}

.tractor-level input{
  width: 140px;
  margin: 10px;
}

.project-sign {
  height: 60px;
}

.tractor-level {
  float: left;
}

.tractor-level input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #BBC8CE;
  outline: none;
  border-radius: 5px;
  box-shadow: -1px -1px 2px #9BA6AA inset;
  opacity: 0.8;
  -webkit-transition: .2s;
  transition: opacity .2s;
  width: 140px;
  margin: 10px;
}

.tractor-level input:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.tractor-level input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 40px; /* Slider handle height */
  border-radius: 6px;
  box-shadow: 6px 8px 4px #073549;
  background: #96A1A5; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.tractor-level input::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}


@media screen and (max-width: 420px) {
  .project-header{
    height: 100px;
  }

}

/****************************************** Village Upgrades ***********************************/

/* .village-upgrade-name{
  max-width: 260px;
} */

.village-blurb {
  max-width: 900px;
  margin: auto;
  padding: 10px;
  
}

.project-blurb {
  max-width: 825px;
  margin: auto;
  padding: 10px 15px;

}

.village-writeup {
  text-align: left;
  max-width: auto;
  overflow: hidden;
  margin: 10px;
}

.village-icon {
  width: 200px;
  float: right;
  margin-left: 15px;
}

.village-table {
  min-width: 200px;
  margin-right: 20px;
  float: left;
}

.plus {
  /* position: relative; */
  /* top: -1px; */
  font-size: 2.2rem;
  font-weight: 800;
}

.village-task-icon{
  position: relative;
  margin-left: 4px;
  top: -6px;
  width: 50px;
}

.village-lights {
  max-width: 900px;
  margin: auto;
  padding: 10px;
  text-align: left;
  
}

@media screen and (max-width: 768px) {
  .village-table {
    float: none;
    margin: 30px auto;
  }
}

@media screen and (max-width: 576px){
  .village-icon {
    width: 120px;
    float: right;
    
  }
}

@media screen and (max-width: 442px){
    .village-upgrade-name{
    max-width: 160px;
  }
}

.coop-lights {
  position: relative;
  top: -2.5px;
  margin-right: 10px;
}


/************************************************ Tables ***************************************************/
th {
  background-color: #23414F;
  color: #BBC8CE;
  font-size: 1.2rem;
  font-weight: 500;
  width: 120px
}

/* .level-icon{
  position: relative;
  width: 116px;
  top: -3px;
} */

td {
  margin: 5px 5px;
  padding: 4px 4px 4px 0;
  font-size: 1.2rem;
  white-space: nowrap
}

.task-icon {
  position: relative;
  top: -1px;
  margin: 4px 2px 4px 6px;
  height: 25px;
  width: 25px;
  z-index: 0;
}

th .task-icon {
  height: 30px;
  width: 30px;
}

/* th .task-icon{
  height: 30px;
  width: 30px;
} */

.container {
  position: relative;
}

tr {
height: 40px;
}

tr:nth-child(even) {
  background-color: #23414F;
  color: #BBC8CE;
  font-weight: 400;

}

tr:nth-child(odd){
  background-color: #BBC8CE;
  color:  #23414F;
  font-weight: 500;
}
 
.left-border {
  border-left: 1px solid #BBC8CE;
}

tbody tr:nth-child(odd) .left-border:nth-child(n+2){
  border-left: 1px solid #23414F;
}

#rate-table th {
  width: 80px
}

#rate-table th:nth-child(1){
  width: 50px
}


/* ******************************* Value Table **************************************************** */

.value-table-overflow {
  height: 1200px;
  overflow: auto;
  width: fit-content;
  margin: 5px;
  border: 1px solid #BBC8CE;
}

/* th.value-table-overflow, .sticky-head { */
.sticky-head {
  position: sticky;
  top: 0;
  z-index: 1;
  /* background-color: #23414F; */
  background-color: red;
}

th:first-child.value-table-overflow, td:first-child.value-table-overflow {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #23414F;
  
}

th:first-child.value-table-overflow  {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: #23414F;
  
}

tr:nth-child(even).value-table-overflow td:first-child.value-table-overflow {
  background-color: #23414F;
  color: #BBC8CE;
  font-weight: 400;
}

tr:nth-child(odd).value-table-overflow  td:first-child.value-table-overflow  {
  background-color: #BBC8CE;
  color:  #23414F;
  font-weight: 500;
}

.value-table {
 
  text-align: center;
  max-width: 95%;
  
} 
/* 
#theme-table tr:nth-child(even), #rate-table tr:nth-child(even) {
  background-color: #23414F;
  color: #BBC8CE;
  font-weight: 400;

}

#theme-table tr:nth-child(odd), #rate-table tr:nth-child(odd){
  background-color: #BBC8CE;
  color:  #23414F;
  font-weight: 500;
} */
 
/* background-color: #BBC8CE;
  color:  #23414F; */

 


.value-table-header {
  padding: 5px;
  width: 70px;
  height: 70px;
  
}

.value-table-legend {
  padding: 4px;
  width: 40px;
  height: 40px;
}

.value-table-row { 
  text-align: right;
}

.value-icon {
  position: relative;
  top: -1px;
  margin-left: 5px;
  height: 25px;
  width: 25px;
  z-index: 0;
}

.product-note {
  position: relative;
  top: -10px;
  left: 3px;
  
  font-size: 1rem;

}

.value-card-icon {
  position: relative;
  top: -4px;
  margin-right: 8px;
  margin-left: 8px;
  height: 40px;
  width: 40px;
  z-index: 0;
} 

/* Not valid */
/* .value-market-td {
  position: relative;
  padding-left: 40px;
  
} */ 

/* /* .value-market-cash {
  float: left;
  /* position: absolute; */
  /* left: 0; 
  top: 7px; 
} /* */

.value-market-xp {
  position: absolute;
  left: 0;
  top: 36px; 
  
}

.orange-box {
  border: 2px solid orange;
}

